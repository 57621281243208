import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SimpleCard from "../components/simpleCard"
import bookImage from "../images/basket_san_dona_pt2.jpeg"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  book: {
    marginTop: "50px",
  },
  bookImage: {
    maxHeight: "70vh",
    borderRadius: "12px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "10%",
      marginRight: "10%",
      maxHeight: "50vh",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "35vw",
      marginLeft: "10%",
      marginRight: "10%",
    },
  },
  bookText: {
    color: "#fff",
  },
  bookTextPrimary: {
    color: "#d3a20f",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "1.5rem",
  },
  bookTextSecondary: {
    color: "#d3a20f",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "1rem",
  },
  bookButton: {
    backgroundColor: "#f2545b",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#a93a3f",
    },
  },
  details: {
    marginTop: "100px",
  },
  content: {
    marginTop: "100px",
  },
  heading: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: 800,
    fontSize: "25px",
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "red",
  },
}))

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    "& > span": {
      backgroundColor: "#f2545b",
      color: "black",
    },
    scrollButtons: {
      backgroundColor: "black",
    },
  },
})(props => (
  <Tabs
    variant="scrollable"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
))

const StyledTab = withStyles(theme => ({
  root: {
    fontSize: "15px",
    marginRight: theme.spacing(1),
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: "800",
    backgroundColor: "#f2545b",
    color: "black",
    "&:focus": {
      opacity: 1,
    },
    "&:hover": {
      border: "1px solid #305aed",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
}))(props => <Tab disableRipple {...props} />)

const Libro = ({ data }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  useEffect(() => {
    if (window.location.href.indexOf("#content") > -1) {
      setValue(1)
    }
  }, [])

  return (
    <Layout title="Il basket a San Donà e dintorni. Origini storia documenti 1929-1978 - Giambattista Ferrari - Libro - Mazzanti Libri">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container className={classes.book}>
              <Grid item xs={12} sm={6}>
                <img
                  src={bookImage}
                  className={classes.bookImage}
                  alt="Il basket a San Donà e dintorni 1929-1978 Origini Storia Documenti di Giambattista Ferrari"
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.bookText}>
                <strong className={classes.bookTextPrimary}>
                  IL BASKET A SAN DONÀ e dintorni
                </strong>
                <p className={classes.bookTextSecondary}>
                  1929-1978
                  <br />
                  Origini Storia Documenti
                  <br />
                  di Giambattista Ferrari
                </p>
                <p>
                  Si terrà la presentazione del libro il giorno
                  <strong> Venerdì 10 giugno 2022, ore 18.30 </strong>
                  presso la<strong> BIBLIOTECA CIVICA di JESOLO</strong>
                  <span> in Sala Gelli</span>.
                </p>
                <p>
                  "Se non c'è qualcuno che raccoglie una testimonianza, qualcuno
                  che ne scrive, qualcuno che fa una foto, qualcuno che ne
                  lascia una traccia in un libro, è come se i fatti non fossero
                  mai avvenuti! Perchè la storia esiste solo se qualcuno la
                  racconta"
                  <br />
                  <span style={{ color: "#d3a20f", fontWeight: "bold" }}>
                    Roberto Pellizzaro
                  </span>
                </p>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Squadre dei periodi"
                  className={classes.tabsMenu}
                >
                  <StyledTab
                    label="MODALITÀ ACQUISTO LIBRO"
                    href="#details"
                    {...a11yProps(0)}
                  />
                  <StyledTab
                    label="CONTENUTI LIBRO"
                    href="#content"
                    {...a11yProps(1)}
                  />
                </StyledTabs>
              </Grid>
            </Grid>
          </Container>
        </section>
        <TabPanel value={value} index={0}>
          <section className={classes.details}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.heading} id="details">
                    Istruzioni per l'acquisto
                  </Typography>
                  <p>
                    Per acquistare il libro{" "}
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      “Il Basket a San Donà e dintorni”
                    </span>{" "}
                    si prega seguire queste istruzioni:
                  </p>
                  <ol>
                    <li>
                      inviare una mail al seguente indirizzo:{" "}
                      <a href="mailto:gb49.ferrari@libero.it">
                        gb49.ferrari@libero.it
                      </a>
                    </li>
                    <li>
                      specificare il nome e cognome dell’ordinante e l’indirizzo
                      dove si desidera venga recapitata la copia
                    </li>
                    <li>
                      effettuare il versamento di euro 27,50 € (24,00 € per il
                      libro + 3,50 € spese di spedizione) a mezzo bonifico:
                      <ul>
                        <li>
                          codice IBAN:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            IT77M0890436280057011011522
                          </span>{" "}
                          oppure a
                        </li>
                        <li>
                          codice IBAN:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            IT96S0200836282000002613883
                          </span>{" "}
                        </li>
                        <li>
                          intestato a{" "}
                          <span style={{ fontWeight: "bold" }}>
                            ASD JesoloSandonà Basket
                          </span>
                        </li>
                        <li>
                          causale:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            nome cognome per copia libro
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ol>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.heading}>
                    Presentazione del libro “IL BASKET A SAN DONA’ E DINTORNI”
                  </Typography>
                  <p>
                    Lo scopo del libro è quello di documentare la storia della
                    pallacanestro sandonatese nel periodo compreso tra il 1929
                    ed il 1978, ampliandola a quanto accaduto nei suoi
                    “dintorni”, cioè tra il Piave ed il Tagliamento nel
                    cosiddetto Veneto Orientale.
                  </p>
                  <p>
                    E’ stata effettuata una ricerca sia sotto il profilo
                    infrastrutturale (campi da gioco e palestre), che sotto
                    quello organizzativo, relativamente alle società ed
                    associazioni sportive cresciute in questo territorio.
                  </p>
                  <p>
                    Sono stati recuperati risultati, articoli e testimonianze di
                    gran parte degli eventi disputati nei campionati e nei
                    tornei organizzati dalla Federazione Italiana Pallacanestro,
                    che possono essere rivissuti attraverso la lettura delle
                    cronache dei quotidiani locali riportate nel libro, oppure
                    esaminando i molti dati statistici (tabellini, risultati e
                    classifiche) pubblicati nel sito{" "}
                    <Link to="/">www.jesolosandonabasket.it</Link>
                  </p>
                  <p>
                    Tutto questo materiale è stato ritrovato negli archivi delle
                    varie società, o conservato da alcuni dei protagonisti delle
                    vicende sportive, o raccolto grazie alla consultazione de Il
                    Gazzettino, del Messaggero Veneto e del Piccolo di Trieste.
                    Inoltre sono state utilissime le numerose fotografie che,
                    più delle parole, hanno colorato le storie raccontate da ex
                    atleti o ex atlete, testimonianze delle esperienze e delle
                    emozioni da loro vissute.
                  </p>
                  <p>
                    Si è data luce ad episodi e fatti avvenuti nei campionati
                    meno celebrati della pallacanestro, ma non per questo meno
                    importanti, perché hanno rivestito un ruolo essenziale nella
                    diffusione dei valori di questa disciplina sportiva.
                  </p>
                  <p>
                    Come ha affermato l’amico ex cestista Roberto Pellizzaro,
                    ricordando che{" "}
                    <span style={{ fontStyle: "italic" }}>
                      “la storia esiste solo se qualcuno la racconta”
                    </span>
                    , si è cercato di documentare quanto accadeva nella
                    periferia della pallacanestro meno abituata ai grandi
                    riflettori, ma fondamentale per la formazione di atleti,
                    tecnici, dirigenti, mecenati, tifosi, … che tutti insieme
                    hanno fatto crescere ed affermare il basket italiano a
                    livello nazionale ed internazionale.
                  </p>
                </Grid>
              </Grid>
            </Container>
          </section>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <section className={classes.content}>
            <Container>
              <Grid container>
                <Grid item container spacing={5}>
                  <Grid item xs={12} style={{ marginBottom: "-50px" }}>
                    <h2 className={classes.heading} id="content">
                      Articoli
                    </h2>
                  </Grid>
                  {data.articoli.nodes.map((post, index) => (
                    <Grid item xs={12} sm={6} lg={4} key={index}>
                      <SimpleCard
                        title={post.titolo}
                        slug={"/articoli/" + post.slug}
                        descrizione={post.descrizione}
                        image={post.foto}
                        type="articoli"
                        key={index}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item container spacing={5}>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "70px", marginBottom: "-20px" }}
                  >
                    <h2 className={classes.heading}>Squadre</h2>
                  </Grid>
                  {data.squadre.nodes.map((squadra, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      key={index}
                      style={{ marginTop: "-30px" }}
                    >
                      <SimpleCard
                        type="squadre"
                        title={squadra.nome}
                        slug={"/squadre/" + squadra.slug}
                        image={squadra.foto}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid item container spacing={5}>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: "70px", marginBottom: "-50px" }}
                  >
                    <h2 className={classes.heading}>Manifesti</h2>
                  </Grid>
                  {data.manifesti.nodes.map((manifesto, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={4}
                      key={index}
                      id={manifesto.slug}
                    >
                      <SimpleCard
                        type="manifesti"
                        title={manifesto.titolo}
                        descrizione={manifesto.descrizione}
                        slug={"/manifesti/" + manifesto.slug}
                        image={manifesto.media[0].media}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Container>
          </section>
        </TabPanel>
      </div>
    </Layout>
  )
}

export default Libro
export const query = graphql`
  {
    articoli: allContentfulArticolo(
      filter: {
        categorie: { elemMatch: { titolo: { glob: "Libro" } } }
        node_locale: { eq: "it-IT" }
      }
    ) {
      nodes {
        titolo
        slug
        descrizione
        data(formatString: "DD/MM/YYYY")
        foto {
          title
          description
          fluid {
            src
          }
        }
      }
    }
    squadre: allContentfulSquadra(
      filter: {
        anno: { elemMatch: { anno: { lte: "1978" } } }
        node_locale: { eq: "it-IT" }
      }
      sort: { fields: anno___anno }
    ) {
      nodes {
        nome
        slug
        foto {
          title
          fluid(quality: 100) {
            src
          }
        }
      }
    }
    media: allContentfulMedia(
      filter: {
        categorie: { elemMatch: { titolo: { glob: "Libro" } } }
        node_locale: { eq: "it-IT" }
        inGalleria: { eq: true }
      }
    ) {
      nodes {
        titolo
        slug
        descrizione {
          descrizione
        }
        media {
          fluid(quality: 100) {
            src
          }
        }
      }
    }
    manifesti: allContentfulManifesto(
      filter: {
        categorie: { elemMatch: { titolo: { glob: "Libro" } } }
        node_locale: { eq: "it-IT" }
      }
    ) {
      nodes {
        titolo
        slug
        descrizione
        squadre {
          nome
        }
        media {
          titolo
          descrizione {
            descrizione
          }
          media {
            fluid {
              src
            }
          }
        }
        articoli {
          titolo
        }
      }
    }
  }
`
